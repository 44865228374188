import React, { useLayoutEffect } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import './App.css';

ZoomMtg.setZoomJSLib('/dist/lib', '/av');

function App() {
  useLayoutEffect(() => {
    tryZooming();
    async function tryZooming() {
      const m = window.location.search.match(/\?d=(.+)/)[1];
      const zoomData = JSON.parse(atob(m));
      console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
      const fallbackUrl = `https://zoom.us/j/${zoomData.meetingNumber}?pwd=${zoomData.passWord}`;

      console.log('checkSystemRequirements');
      console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();

      ZoomMtg.init({
        leaveUrl: 'https://www.dagvandebasisschool.nl/',
        isSupportAV: true,
        success: function () {
          ZoomMtg.join({
            signature: zoomData.signature,
            apiKey: 'HnTD6TpvR7aQkUdaN53Qvg',
            meetingNumber: zoomData.meetingNumber,
            userName: zoomData.userName,
            passWord: zoomData.passWord,
            success: () => {
              window.parent?.postMessage(
                {
                  isZoomReady: true,
                  fallbackUrl,
                },
                window.parent.origin
              );
            },
            error: (error) => {
              console.log(error);
              if (error.errorCode === 3707) {
                window.parent?.postMessage(
                  {
                    isZoomReady: false,
                    isMeetingEnded: true,
                  },
                  window.parent.origin
                );
              } else {
                window.parent?.postMessage(
                  {
                    isZoomReady: false,
                    isMeetingEnded: false,
                    fallbackUrl,
                  },
                  window.parent.origin
                );
              }
            },
          });
        },
      });
    }
  }, [window.location.search]);

  return null;
}

export default App;
